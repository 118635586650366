@charset 'UTF-8';

// vendor
@import 'scss/vendor/fonts';
@import 'scss/vendor/flexboxgrid';
@import 'scss/vendor/nes';


body {
    font-size: 14px;
    padding-bottom: 90px;
    opacity: 1 !important;
    overflow-x: hidden;
}
.container-fluid, .container {
    @media (max-width: 1023px) {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
}
.nav {
    padding: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
    margin-bottom: 60px;

    .row {
        align-items: center;

        .col {
            padding-right: 20px;
        }
    }

    .nes-octocat {
        scale: 0.7;
    }

    h1 {
        margin-bottom: 0;
    }

    a {
        color: #333;
        text-decoration: none;
    }
}
.nes-progress {
    height: 25px;
}
.hello {
    margin-bottom: 50px;

    .col {
        text-align: center;

        img {
            margin-bottom: 15px;
        }

        @media (min-width: 1024px) {
            text-align: left;

            img {
                margin-bottom: 0;
            }
        }
    }

    h2 {
        font-size: 16px;
        margin-bottom: 0;
    }

    .col-sm-9 {
        margin: auto;

        @media (min-width: 1024px) {
            margin: inherit;
        }
    }

    .nes-balloon {
        display: block;
        text-align: center;

        @media (min-width: 1024px) {
            display: inline-block;
        }
    }
}
.sticky {
    @media (min-width: 1024px) {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
}
.nes-list {
    margin-bottom: 0;
}
.contact,
.socials {
    margin-top: 50px;
    margin-bottom: 50px;
}

.contact .nes-icon {
    margin-right: 15px;
}


.socials .lists a {
    display: inline-block;
    margin-right: 15px;
}

.content {
    line-height: 1.8rem;
}

.experience-title {
    margin-top: 40px;
}
.skill-list,
.experience-list,
.portfolio-list {
    margin-top: 50px;

    .row {
        margin-bottom: 30px;
    }

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
}
.experience-list {
    img {
        max-width: 150px;
        margin-bottom: 10px;

        @media (min-width: 768px) {
            max-width: 100%;
            margin: 0;
        }
    }
}
.copyright {
    margin-top: 50px;
    font-size: 60%;
    text-align: center;
    color: #333;
}

.nes-container.with-title > .title {
    > div {
        display: table-cell;
        vertical-align: middle;

        h2 {
            font-size: 1rem;
            margin-left: 15px;
            margin-bottom: 0;
        }
    }
}

.portfolio-item {
    margin-bottom: 40px;

    picture,
    img {
        max-width: 100%;
    }

    .description {
        margin-top: 20px;
    }

    .nes-list {
        padding-left: 1.2rem;
        font-size: 12px;
    }
}

.nes-container {
    position: relative;
    background-color: #fff;
}

.bg-xmas,
.bg-image {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    picture {
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}